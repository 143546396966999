import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  LoadMoreScroll,
  NoReports,
  Loading,
} from 'components';
import MHDRequests from 'services/MHDRequests';
import { verifyCPF } from 'utils/AuxiliarFunctions';
import { oids } from 'utils/Constants';
import { formatSurveillanceTimeline } from 'utils/fhirParser';
import Timeline from './Timeline/Timeline';
import './TimelineDrawer.css';

export function TimelineSurveillanceViwer(props) {
  const {
    patientId,
    organizationId,
    selectReport,
    subjectId,
    purposeOfUse,
    subjectToken,
    subjectRole,
  } = props;

  const [loading, setLoading] = useState(true);
  const [allDocuments, setAllDocuments] = useState({
    documents: [],
    nextUrlLink: null,
  });
  const [documentsShown, setDocumentsShown] = useState([]);

  const getTimelineType = useCallback(async (documents) => {
    const data = await formatSurveillanceTimeline(documents);
    setDocumentsShown(data);
  }, [subjectToken]);

  const loadDocuments = useCallback(async () => {
    const patientOid = verifyCPF(patientId) ? oids.cpf : oids.cns;
    setAllDocuments(async (oldAllDocuments) => {
      setLoading(true);
      try {
        const { retTimeline, nextUrlTimeline: nextUrl } = await MHDRequests.getSurveillanceFullTimeline({
          patientId,
          organizationId,
          oid: patientOid,
          next: oldAllDocuments.nextUrlLink,
          purposeOfUse,
          subjectId,
          subjectToken,
          subjectRole,
        });

        const documents = [...oldAllDocuments.documents, ...retTimeline];
        await getTimelineType(documents);
        setLoading(false);
        return {
          documents,
          nextUrlLink: nextUrl,
        };
      } catch (error) {
        console.log(error);
        setLoading(false);
        return oldAllDocuments;
      }
    });
  }, [patientId, getTimelineType]);

  useEffect(() => {
    (async () => {
      await loadDocuments();
    })();
  }, [loadDocuments]);

  return (
    <div className="timelineDrawer-container">
      <div className="timelineDrawer-upperArea">
        {loading && <Loading type="linear" size="medium" />}
      </div>
      <>
        <div className="timelineDrawer-loadMoreScroll">
          <LoadMoreScroll
            length={5}
            hasNext={allDocuments.nextUrlLink || false}
            action={loadDocuments}
          >
            {documentsShown.length === 0
              ? (
                <div style={{ maxWidth: '40vw', marginLeft: '25%' }}>
                  {!loading && <NoReports />}
                </div>
              )
              : (
                <Timeline
                  data={documentsShown}
                  selectReport={selectReport}
                />
              )}
          </LoadMoreScroll>
        </div>
      </>
    </div>
  );
}

TimelineSurveillanceViwer.propTypes = {
  organizationId: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
  selectReport: PropTypes.func.isRequired,
  subjectId: PropTypes.string.isRequired,
  purposeOfUse: PropTypes.string,
  subjectToken: PropTypes.string,
  subjectRole: PropTypes.string,
};

TimelineSurveillanceViwer.defaultProps = {
  purposeOfUse: 'Atendimento',
  subjectToken: '',
  subjectRole: '',
};
