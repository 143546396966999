// FESF
export const colors = {
  background_green: 'white',
  dark_green: '#003B4E',
  medium_green: '#06814A',
  dark_gray: '#7E8584',
  medium_gray: '#C3C9D4',
  light_gray: '#f5f5f5',
  white: 'white',
  light_green: 'rgba(6, 129, 74, 0.5)',
  green: 'green',
  red: 'red',
  black: 'black',
  light_blue: '#00B2BB',
  dark_blue: 'mediumblue',
  almost_black_gray: '#4E4E4E',
  waiting_container: '#0A5A87', // almost dark blue
  transparent: 'transparent',
  text_modal: '#535353', // Cinza marrom escuro
  link_text: '#305B5B', // Verde azulado escuro
  more_itens: 'rgba(255, 255, 255, 0.3)',
  almost_white: 'rgba(255, 255, 255, 0.3)',
  opaque_black: '#919C72',
  level_4_label: 'rgb(7, 48, 59)',
  level_4_value: 'rgba(0, 0, 0, 0.7)',
  //-----------------------------------------------------------------
  attentionColor: '#08824A',
  title1: '#f26568',
  title2: '#08824A',
  title3: '#FFFFFF',
  title4: '#C5C9DF',
  title5: 'black',
  input_background: 'rgba(240,240,240,0.8)',
  background: '#FFFFFF',
  backgroundColor2: '#C1D097',
  background_focus: '#73951470',
  // background_focus: '#2B6057',
  link: '#08824A',
  input_placeholder: '#C1C1CC',
  input_text: 'black',
  text_on_background: '#FFFFFF',
  on_focus_input_background: '#F8F6E1',
  // border: '#707070',
  border: '#FFFFFF',
  button2_background: '#B1CF5F',
  button_background: '#08824A',
  button_background_cancel: '#F33',
  signup_button_background: '#B1CF5F',
  button_text: '#FFFFFF',
  tab_bar_background: 'white',
  tab_bar_icon: 'black',
  modal_container: 'white',
  modal_title: '#56B04F',
  // icon_color: '#2B6057',
  icon_color: 'white',
  icon_no_content: 'black',
  tab_bar_active: 'green',
  // presentation_ehr_background: 'black',
  presentation_ehr_background: 'white',
  presentation_background: '#73951470',
  presentation_text: 'white',
  header_text: '#2B6057',
  background_telephone_screen: 'white',
  background_dark_modal: 'rgba(0, 0, 0, 0.85)',
  background_welcome_screen: '#73951470',
  govBr: '#1351B4',
  // header_text: 'white',

  //= ====================================================================
  // -----------------------------FESF------------------------------------
  borderColor: 'whitesmoke',
  containerColor: 'rgb(255, 255, 255)',
  timelineBackgroundColor: 'rgb(255, 255, 255)',
  accentColor: '#08824A',
  accentColorSoft: '#08824ADD',
  accentColorDarker: '#066040',
  accentColorSecondary: 'rgb(52, 42, 99)',
  accentColorLight: '#b9f1f7',
  secondaryColor: 'rgba(255, 255, 255, 0.664)',
  bgMainColor: 'rgb(253, 253, 253)',

  loadingColor: '#08824A',

  cancelButtonColor: '#820808',
  confirmButtonColor: '#08824A',
  otherButtonColor: 'white',
  disabledButtonColor: 'rgba(172, 172, 172, 0.4)',
  govBgButtonColor: 'white',
  gov2BgButtonColor: '#1351B4',
  govBorderButtonColor: '#1351B4',

  searchbarBorderColor: 'lightgray',

  font1Color: 'rgb(45, 62, 80)',
  /* font2-color: #8208EE, */
  font2Color: '#08824A',
  font3Color: '#005c64',
  font4Color: 'white',
  font5Color: 'rgb(100, 100, 100)',
  font6Color: '#1351B4',

  warningColor: 'red',

  borderContainer: {
    borderWidth: '3px',
    borderColor: 'whitesmoke',
    border: 'solid',
  },
  borderRadiusContainer: 0,

  icon1Color: 'gray',

  qrCodeBgColor: 'rgb(212, 212, 212)',
};
