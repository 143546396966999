import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { HeaderBottomAndLateral, PageMask, NewPageMask } from 'components';
import 'theme/global.css';
import { useUserContext } from 'store';
import { extractMainUserData, fetchUserData } from 'utils/userFunctions';
import { Pages, getInitialPageId } from 'components/HeaderBottomAndLateral/Pages';
import { NoMatch } from 'pages/NoMatch';
import i18n from 'i18n';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { VisualizerContextProvider } from 'contexts/VisualizerContext';
import { Provider } from 'react-redux';
import professionalOrgStore from 'store/ProfessionalOrgStore';
import { NewHeaderBottomAndLateral } from 'components/NewHeaderBottomAndLateral';

export default function LoggedUserPortal() {
  const [user, setUser] = useUserContext();
  const [active, setActive] = useState(false);
  const [availablePages, setAvailablePages] = useState([]);
  const [initialPageId, setInitialPageId] = useState('');
  const { t } = useTranslation('PageMask');

  useEffect(() => {
    async function initializeUserContext() {
      const userDataResp = await fetchUserData();
      const extractedData = await extractMainUserData(userDataResp);
      const contextObject = { userData: userDataResp, ...extractedData };
      setAvailablePages(Pages(contextObject.role).filter((page) => page.active));
      setInitialPageId(getInitialPageId(contextObject.role));
      setUser(contextObject);
    }
    if (user.userData === undefined || user.userData === null) initializeUserContext().then(() => setActive(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const NoMatchPage = () => (
    <PageMask active={active} title="Página não encontrada">
      <NoMatch />
    </PageMask>
  );

  if (!active) return <PageMask title=" " active={active} />;
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <VisualizerContextProvider>
          <Provider store={professionalOrgStore}>
            {user.role.isProfessional && <NewHeaderBottomAndLateral pages={availablePages} active />}
            {!user.role.isProfessional && <HeaderBottomAndLateral pages={availablePages} active />}

            <main className="pages-container">

              <Route
                exact
                path="/"
                component={() => {
                  const InitialPage = availablePages.find(
                    (page) => page.id === initialPageId,
                  );
                  const InitialPageComponent = InitialPage.component;
                  if (!user.role.isProfessional) {
                    return (
                      <>
                        <PageMask title={t(InitialPage.title)} active>
                          <InitialPageComponent />
                        </PageMask>
                      </>
                    )
                  }
                  return (
                    <>
                      <NewPageMask title={t(InitialPage.title)} userName={user.name} userGender={user.gender} orgsProfessional={[]} active />
                      <InitialPageComponent />
                    </>
              );
                }}
              />
              <Route
                path="/:pageId"
                component={({ match }) => {
                  const { pageId } = match.params;
                  const MatchPage = availablePages.find(
                    (page) => page.id === pageId,
                  );
                  const MatchPageComponent = MatchPage?.component;
                  if (MatchPage.newLayout && MatchPage.newLayout == true) {
                    return MatchPageComponent ? (
                      <>
                        <NewPageMask title={t(MatchPage.title)} userName={user.name} userGender={user.gender} orgsProfessional={[]} active />
                        <MatchPageComponent />
                      </>
                        ) : (
                      <NoMatchPage />
                    );  
                  } else {
                    return MatchPageComponent ? (
                      <PageMask title={t(MatchPage.title)} active>
                        <MatchPageComponent />
                      </PageMask>
                    ) : (
                      <NoMatchPage />
                    );  
                  }
                }}
              />
            </main>
          </Provider>
        </VisualizerContextProvider>
      </I18nextProvider>
    </>
  );
}
