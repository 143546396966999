import React from 'react';
import { AllergyTabContent } from './SummaryTab/AllergyTabContent';
import { DiagnosisTabContent } from './SummaryTab/DiagnosisTabContent';
import { ExamResultsTabContent } from './SummaryTab/ExamResultsTabContent';
import { HospitalizationsTabContent } from './SummaryTab/HospitalizationsTabContent';
import { MedicationsTabContent } from './SummaryTab/MedicationsTabContent';
import { ObservationTabContent } from './SummaryTab/ObservationTabContent';
import { ProblemsTabContent } from './SummaryTab/ProblemsTabContent';
import { ProceduresTabContent } from './SummaryTab/ProceduresTabContent';
import { ServicesRequestsTabContent } from './SummaryTab/ServicesRequestsTabContent';
import { VaccinesTabContent } from './SummaryTab/VaccinesTabContent';
import { MedicationsDispensedTabContent } from './SummaryTab/MedicationsDispensedTabContent';
import { ImmunizationTabContent } from './SummaryTab/ImmunizationTabContent';

const tabsInfoArray = () => [
  {
    id: 'observation',
    name: 'Observações',
    hideName: true,
    canLoadMore: true,
    neverCloses: true,
    initOpen: true,
    urlDescriptor: ['/Observation'],
    Component: (props) => <ObservationTabContent {...props} />,
  },
  {
    id: 'diagnosis',
    name: 'tabsInfo-patientSummary-healthSummary-tab-diagnosis',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/Condition', '2.16.840.1.113883.6.3'],
    Component: (props) => <DiagnosisTabContent {...props} />,
  },
  {
    id: 'problems',
    name: 'Problemas',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/Condition', '2.16.840.1.113883.6.139'],
    Component: (props) => <ProblemsTabContent {...props} />,
  },
  {
    id: 'allergies',
    name: 'tabsInfo-patientSummary-healthSummary-tab-allergies',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/AllergyIntolerance'],
    Component: (props) => <AllergyTabContent {...props} />,
  },
  {
    id: 'medications',
    name: 'tabsInfo-patientSummary-healthSummary-tab-medications',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/MedicationRequest'],
    Component: (props) => <MedicationsTabContent {...props} />,
  },

  {
    id: 'medicationsDispense',
    name: 'tabsInfo-patientSummary-healthSummary-tab-medications-dispensed',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/MedicationDispense'],
    Component: (props) => <MedicationsDispensedTabContent {...props} />,
  },
  {
    id: 'examResults',
    name: 'tabsInfo-patientSummary-healthSummary-tab-exam-results',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/DocumentReference', 'ResultadoExames_FESF_V0.1'],
    Component: (props) => <ExamResultsTabContent {...props} />,
  },
  {
    id: 'servicesRequests',
    name: 'tabsInfo-patientSummary-healthSummary-tab-exam-requests',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/ServiceRequest'],
    Component: (props) => <ServicesRequestsTabContent {...props} />,
  },
  {
    id: 'hospitalizations',
    name: 'tabsInfo-patientSummary-healthSummary-tab-hospitalizations',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/DocumentReference', 'Sum%C3%A1rioAlta.v4.0'],
    Component: (props) => <HospitalizationsTabContent {...props} />,
  },
  {
    id: 'vaccines',
    name: 'Registros de Imunobiológicos',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/DocumentReference', 'RegistroImunobiologico_FESF_v1.0'],
    Component: (props) => <VaccinesTabContent {...props} />,
  },
  {
    id: 'immunization',
    name: 'tabsInfo-patientSummary-healthSummary-tab-immunization',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: true,
    urlDescriptor: ['/Immunization'],
    Component: (props) => <ImmunizationTabContent {...props}/>,
  },
  {
    id: 'procedures',
    name: 'tabsInfo-patientSummary-healthSummary-tab-procedures',
    hideName: false,
    canLoadMore: true,
    neverCloses: false,
    initOpen: false,
    urlDescriptor: ['/Procedure'],
    Component: (props) => <ProceduresTabContent {...props} />,
  }
];

const tabsInfoJson = () => {
  const tabsInfo = {};
  tabsInfoArray().forEach((tab) => {
    tabsInfo[tab.id] = { ...tab };
  });
  return tabsInfo;
};

const findTabIdFromUrl = (url) => {
  let tabId = null;
  tabsInfoArray().forEach((tab) => {
    const tabValidator = tab.urlDescriptor.map((descriptor) => {
      if (url.includes(descriptor)) return true;
      return false;
    });
    if (!tabValidator.includes(false)) tabId = tab.id;
  });

  return tabId;
};

export { tabsInfoArray, tabsInfoJson, findTabIdFromUrl };
