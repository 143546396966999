import React, {
    useEffect, useState,
  } from 'react';
  import PropTypes from 'prop-types';
  
  import {
    PrincipalHeaderBottom,
    NewPrincipalHeaderLateral,
  } from 'components';
  import './NewHeaderBottomAndLateral.css';
  import profileRequests from 'services/profileRequests';
  import {
    logDebug,
  } from 'utils/AuxiliarFunctions';
  import './PrincipalHeader.css';
  import { useUserContext } from 'store/UserContext';
  
  function NewHeaderBottomAndLateral({ pages, active }) {
    const [user] = useUserContext();
    const userData = user?.userData;
    const [optionsMenu, setOptionsMenu] = useState([]);
    const [loading, setLoading] = useState(true);
    const [init, setInit] = useState(false);
  
    async function createOptionsMenu() {
      const options = pages.map((page) => {
        const doesNotContainIconMenu = !page.iconName || page.iconName === ' ';
        return doesNotContainIconMenu ? null : page;
      }).filter((item) => !!item);
      setOptionsMenu(options);
    }
  
    useEffect(() => {
      async function asyncCalls() {
        try {
          await profileRequests.refreshProfileData();
        } catch (err) {
          logDebug('err :>> ', err);
        }
      }
      asyncCalls().then(() => setInit(true));
      return () => {
      };
    }, []);
  
    useEffect(() => {
      async function asyncCalls() {
        await createOptionsMenu();
      }
      if (init && userData) {
        setLoading(true);
        asyncCalls().then(() => setLoading(false));
      }
      return () => {
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, init]);
  
    async function selectedPageFunc() {
      setLoading(false);
    }
  
    if (loading || !active) return null;
  
    return (
      <>
        <div className="header_centralize">
          <div
            className="side_bar"
            style={{ backgroundColor: 'white', overflow: 'hidden' }}
          >
            <NewPrincipalHeaderLateral
              optionsMenu={optionsMenu}
            />
          </div>
        </div>
        <div
          className="bottom_bar"
          style={{ backgroundColor: 'white' }}
        >
          <PrincipalHeaderBottom
            optionsMenu={optionsMenu}
            redirectToPage={selectedPageFunc}
          />
        </div>
      </>
    );
  }
  
  NewHeaderBottomAndLateral.propTypes = {
    active: PropTypes.bool.isRequired,
    pages: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onAccess: PropTypes.func.isRequired,
        iconName: PropTypes.string,
        active: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  
  };
  
  export { NewHeaderBottomAndLateral };
  