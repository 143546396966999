import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './NewPageMask.css';

import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

import { DropDownTranslate } from 'components/DropdownTranslate';
import { DropdownOrganization } from 'components/DropdownOrganization';

function NewPageMask({ userName, userGender }) {

    const [firstName, setFirstName] = useState("");
    const [gender, setGender] = useState("");
    const { t } = useTranslation('NewPageMask', { i18n });

    useEffect(() => {
        setFirstName(userName.split(" ")[0]);
        setGender(userGender);
    }, []);

    return (
        <>
            <div className="newpagemask-section">
                <div className="newpagemask-toolbar">
                    <span className="newpagemask-welcome">{
                        gender == 'Masculino' ?
                    t('NewPageMask-welcomeMessage-male')
                    : t('NewPageMask-welcomeMessage-female')
                    }, <b style={{fontWeight: 'bold'}}>{firstName}</b></span>

                    <div className="newpagemask-dropdowns">
                        <DropdownOrganization showTitle={true} style={{ marginRight: '5rem' }} />
                        <DropDownTranslate  showTitle={true} />
                    </div>
                </div>
            </div>
        </>
    );

}

NewPageMask.defaultProps = {
    active: true
}

NewPageMask.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    active: PropTypes.bool
}

export { NewPageMask };