/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
import extract from 'xds-extractor';

import {
  retrieveData,
  // capitalize_first_letters,
  // parse_reports,
  fetchWithTimeout,
  verifyCPF,
  extractDocType,
  atou,
  verifyUnauthorized,
  logDebug,
  CNSisValid,
} from 'utils/AuxiliarFunctions';

import { endpoints, oids } from 'configs';
import xdsToken from './xdsToken';

const {
  MHD, MPI_FHIR, USER_FHIR,
} = endpoints;

const DEBUGMODE = false;

/**
 * This document coverage the
 * MHD requests for Document
 * References and Services Requests
 */

class MHDRequests {
  static catchError = (err, from) => {
    logDebug(`ERROR in MHDRequests: ${from}: `, err);
    switch (parseInt(err.message, 10)) {
      case 500:
        return 'internal_error';
      case 504:
        return 'timeout';
      case 400:
        return 'bad_request';
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 409:
        return 'conflict';
      case 404:
        return 'not_found';
      default:
        return 'network_error';
    }
  };

  static async getCnes(id = null) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const username = id || (await retrieveData('username'));
    // logDebug('username:', username);
    const url = `${MHD}/PractitionerRole?practitioner=Practitioner%2F${oids.cpf}-${username}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const cneses = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        // logDebug('response', response);
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => {
        switch (err) {
          case 500:
            return 'internal_error';
          case 400:
            return 'bad_request';
          default:
            return 'network_error';
        }
      });

    try {
      let response = await cneses.json();
      response = response.entry;
      let cnesList = response.map(
        (item) => item.resource.organization.reference,
      );
      cnesList = cnesList.map((item) => item.split('-')[1]);
      return cnesList;
    } catch (e) {
      return null;
    }
  }

  static async advancedSearch(name, birthdate = null, gender = null) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    let url = `${MPI_FHIR}/Patient?name=${name}`;
    if (birthdate) url = `${url}&birthdate=${birthdate}`;
    if (gender) url = `${url}&gender=${gender}`;
    url = encodeURI(url);
    const myHeaders = new Headers();

    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const search = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 202 && response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => {
        this.catchError(err);
      });

    return search;
  }

  static async getMoreSummaryTabDocuments({
    patientId,
    organizationId,
    nextLinkUrl,
    subjectToken,
  }) {
    const { token, applicationToken, purposeOfUse } = await MHDRequests.preMhdRequest(patientId);

    // Extrai os parâmetros necessários para a requisição da página
    // Ex.:
    // para nextLinkUrl "https://sumpaciente-interopteste.haoc.com.br:8243/ehrrunner/fhir?_getpages=a553cc5d-fd2c-4e6f-a601-785560d5ee63&_getpagesoffset=5&_count=5&_pretty=true&_bundletype=searchset"
    // temos "?_getpages=a553cc5d-fd2c-4e6f-a601-785560d5ee63&_getpagesoffset=5&_count=5&_pretty=true&_bundletype=searchset"
    const pagRequestParams = nextLinkUrl.split('ehrrunner/fhir')[1];

    const url = `${MHD}/${pagRequestParams}`;
    // const url = `https://dev-reds.ebserh.gov.br/ehrrunner/fhir/${pagRequestParams}`;
    const username = await retrieveData('username');
    const orgID = username !== patientId ? organizationId : null;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token || subjectToken);
    // if (subjectId) myHeaders.append('subject-id', subjectId);
    // if (typeof subjectRole === 'string') myHeaders.append('role', subjectRole);

    const doc_req = {
      method: 'GET',
      headers: myHeaders,
    };

    const MHDResponse = await fetchWithTimeout(url, doc_req)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getMoreSummaryTabDocuments'));

    return MHDResponse;
  }

  /**
   *
   * @param {string} username
   * @param {string} cnes
   * @param {string} type
   */
  static async getPatientSummaryType(username, cnes, type) {
    let res;
    switch (type) {
      case 'Observações':
        res = await MHDRequests.getBloodPressure(username, cnes);
        break;
      case 'Diagnósticos':
        res = await MHDRequests.getDiagnosis(username, cnes);
        break;
      case 'Problemas':
        res = await MHDRequests.getProblem(username, cnes);
        break;
      case 'Alergias':
        res = await MHDRequests.getAllergies(username, cnes);
        break;
      case 'Procedimentos':
        res = await MHDRequests.getProcedures(username, cnes);
        break;
      case 'Medicamentos':
        res = await MHDRequests.getMedications(username, cnes);
        break;
      case 'Exames':
        res = await MHDRequests.getExams(username, cnes);
        break;
      case 'Internações':
        res = await MHDRequests.getInternment(username, cnes);
        break;
      case 'Resultados de Exame':
        res = await MHDRequests.getResult(username, cnes);
        break;
      default:
        break;
    }
    return res;
  }

  /** This function is responsible for
   * creating the headers for the
   * MHD requests
   * @param {string} organizationId
   * @param {string} token - X-User-Token
   * @param {string} applicationToken - Token for Authorization
   * @param {string} purposeOfUse
   */
  static myHeaders(
    token,
    applicationToken,
    purposeOfUse,
    organizationId = null,
  ) {
    const myHeaders = new Headers();
    if (organizationId) myHeaders.append('organization-id', organizationId);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    return myHeaders;
  }

  /** This function is responsible
   * for making the actual request
   * @param {string} url - Endpoint for the fetch
   * @param {object} requestOptions - Data for the fetch
   * @return {Promise} - All the data that was request with return treated SPECIFICALLY
   *  for these types of entry, it originally differed from the Bundle return.
   */
  static async makeFetchMHD(requestOptions, url, from) {
    //
    const MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, from));

    verifyUnauthorized(MHD_response);
    if (typeof MHD_response === 'string') return MHD_response;

    const entry = { entry: [{ resource: { entry: MHD_response.entry } }] };
    return entry;
  }

  /** This function is responsible
   * for getting the access token
   * and allow the user to make the
   * request
   * @param {string} patientId
   * @returns {Array<string>}
   */
  static async preMhdRequest(patientId) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;
    let patientOid;
    const isCpf = verifyCPF(patientId);
    if (isCpf) patientOid = oids.cpf;
    else patientOid = oids.cns;
    return {
      token,
      applicationToken,
      patientOid,
      purposeOfUse,
    };
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Medication requests
   * @param {string} username - user's id (CPF or CNS)
   * @param {string} organizationId - represent's the organization
   * @returns {Promise}
   */
  static async getMedications(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/MedicationRequest?subject=Patient/${patientOid}-${patientId}&_sort=-authoredon&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    const MHDResponse = MHDRequests.makeFetchMHD(
      doc_req,
      path,
      'getMedications',
    );
    return MHDResponse;
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Allergies and Intolerances
   * @param {string} username - user's id (CPF by default)
   * @returns {Promise}
   */
  static async getAllergies(patientId, organizationId) {
    // Is not working -> 409-Conflit
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/AllergyIntolerance?patient=Patient/${patientOid}-${patientId}&_sort=-date&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getAllergies');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Procedures
   * @param {string} patientId - user's id (CPF by default)
   * @param {string} organizationId - cnes of the organization
   * @returns {Object}
   */
  static async getProcedures(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/Procedure?subject=Patient/${patientOid}-${patientId}&_sort=-date&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getProcedures');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Conditions
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getDiagnosis(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${USER_FHIR}/Condition?code=2.16.840.1.113883.6.3%7C&subject=Patient/${patientOid}-${username}&_sort=-recorded-date&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    const request = await MHDRequests.makeFetchMHD(
      doc_req,
      path,
      'getDiagnosis',
    );
    return request;
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * more conditions
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getProblem(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${USER_FHIR}/Condition?code=2.16.840.1.113883.6.139%7C&subject=Patient/${patientOid}-${username}&_sort=-recorded-date&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getProblem');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Document References 1
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getExams(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/DocumentReference?subject=Patient/${patientOid}-${username}&type:coding=Sum%C3%A1rioAlta.v4.0,Sum%C3%A1rio%20de%20Alta%20v1.0&_sort=-date&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getExams');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Document References 2
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getInternment(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/DocumentReference?subject=Patient/${patientOid}-${username}&type:coding=ResultadoExames_FESF_V0.1&_sort=-date&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getInternment');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * Services Requests
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getResultExam(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/ServiceRequest?subject=Patient/${patientOid}-${username}&_sort=-authored&_count=30`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getResultExam');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * blood pressure
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getBloodPressure(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/Observation?code=http://loinc.org%7C55284-4&subject=Patient/${patientOid}-${username}&_sort=-date&_count=1`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getBloodPressure');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * weight
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getWeight(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/Observation?code=29463-7&subject=Patient/${patientOid}-${username}&_sort=-date&_count=1`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getWeight');
  }

  /**
   * This is a function that requests
   * on MHD and return the patient's
   * height
   * @param {string} username - user's id (CPF by default)
   * @returns {Object}
   */
  static async getHeight(patientId, organizationId) {
    const {
      token, applicationToken, patientOid, purposeOfUse,
    } = await MHDRequests.preMhdRequest(patientId);
    const username = await retrieveData('username');
    const path = `${MHD}/Observation?code=8302-2&subject=Patient/${patientOid}-${username}&_sort=-date&_count=1`;
    const orgID = username !== patientId ? organizationId : null;
    const doc_req = {
      method: 'GET',
      headers: MHDRequests.myHeaders(
        token,
        applicationToken,
        purposeOfUse,
        orgID,
      ),
    };
    return MHDRequests.makeFetchMHD(doc_req, path, 'getHeight');
  }

  static getEntriesTimeline = (timeline) => timeline.entry;

  /**
   * This is a function consumes the MHD API to get the timeline for that patient
   * @param {string} patientId - patientId for that patient
   * @param {string} organizationId - organizationId for that patient
   * @param {string} type - type of document for timeline for that patient
   * @param {string} oid - oid for that patient (CPF or CNS)
   * @param {string} next - search by ID of next page of documents
   * @param {string} timeInit - set initial date to filter
   * @param {string} timeEnd - set final date to filter
   * @returns {Object}
   */
  static async getFullTimeline({
    patientId,
    organizationId,
    type = null,
    oid = null,
    next = null,
    timeInit = null,
    timeEnd = null,
    purposeOfUse,
    subjectId,
    subjectToken,
    subjectRole,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    let url = '';
    if (next) url = next;
    else {
      let patient_oid = oids.cpf;
      if (oid) patient_oid = oid;
      const status = 'current';
      let typeQ = '';
      if (type) typeQ = `&type=${type}`;

      const query = `?_count=5&subject:Patient.identifier=urn:oid:${patient_oid}%7C${patientId}&status=${status}&format=tdd,${oids.documentPdf}${typeQ}&_sort=-period&status=current`;

      url = `${MHD}/DocumentReference${query}`;

      if (timeInit) url = `${url}&hora=gt${timeInit}`;
      if (timeEnd) url = `${url}&hora=lt${timeEnd}`;
    }

    const myHeaders = new Headers();

    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token || subjectToken);
    myHeaders.append('Content-Type', 'application/fhir+json');
    if (subjectId) myHeaders.append('subject-id', subjectId);
    if (typeof subjectRole === 'string' && subjectRole !== '') myHeaders.append('role', subjectRole);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getFullTimeline'));

    verifyUnauthorized(MHD_response);

    if (typeof MHD_response === 'string') return [MHD_response];

    let nextUrlTimeline = MHD_response.link.filter(
      (li) => li.relation === 'next',
    );

    try {
      nextUrlTimeline = nextUrlTimeline[0].url;
      nextUrlTimeline = `${MHD}/${nextUrlTimeline.slice(nextUrlTimeline.indexOf('?_getpages'))}`;
    } catch (err) {
      nextUrlTimeline = null;
    }

    const retTimeline = this.getEntriesTimeline(MHD_response);
    logDebug('nextUrlTimeline', nextUrlTimeline);
    if (nextUrlTimeline) return { retTimeline, nextUrlTimeline };
    return { retTimeline };
  }

  static async getSurveillanceFullTimeline({
    patientId,
    organizationId,
    oid = null,
    next = null,
    timeInit = null,
    timeEnd = null,
    purposeOfUse,
    subjectId,
    subjectToken,
    subjectRole,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    let url = '';
    if (next) url = next;
    else {
      let patient_oid = oids.cns;
      if (oid) patient_oid = oid;

      const query = `?composition.subject=Patient/${patient_oid}-${patientId}&composition.type=55751-2&composition.category=NotificacaoIndividual&_count=5`;
      url = `${MHD}/Bundle${query}`;
      if (timeInit) url = `${url}&hora=gt${timeInit}`;
      if (timeEnd) url = `${url}&hora=lt${timeEnd}`;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token || subjectToken);
    myHeaders.append('Content-Type', 'application/fhir+json');
    if (subjectId) myHeaders.append('subject-id', subjectId);
    if (typeof subjectRole === 'string' && subjectRole !== '') myHeaders.append('role', subjectRole);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getExamFullTimeline'));

    verifyUnauthorized(MHD_response);

    if (typeof MHD_response === 'string') return [MHD_response];

    let nextUrlTimeline = MHD_response.link.filter(
      (li) => li.relation === 'next',
    );

    try {
      nextUrlTimeline = nextUrlTimeline[0].url;
      nextUrlTimeline = `${MHD}/${nextUrlTimeline.slice(nextUrlTimeline.indexOf('?_getpages'))}`;
    } catch (err) {
      nextUrlTimeline = null;
    }
    const retTimeline = MHD_response.entry;
    if (nextUrlTimeline) return { retTimeline, nextUrlTimeline };
    return { retTimeline };
  }

  static async getExamFullTimeline({
    patientId,
    organizationId,
    // type = null,
    oid = null,
    next = null,
    timeInit = null,
    timeEnd = null,
    purposeOfUse,
    subjectId,
    subjectToken,
    subjectRole,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    let url = '';
    if (next) url = next;
    else {
      let patient_oid = oids.cns;
      if (oid) patient_oid = oid;
      const status = 'final';
      // const category = 'LAB';
      // let typeQ = '';
      // if (type) typeQ = `&type=${type}`;

      // let cnsTest = '703000897726579';
      const query = `?subject:Patient.identifier=urn:oid:${patient_oid}%7C${patientId}&_sort=-issued&_include=DiagnosticReport:based-on&status=${status}&_count=5`;

      url = `${MHD}/DiagnosticReport${query}`;

      if (timeInit) url = `${url}&hora=gt${timeInit}`;
      if (timeEnd) url = `${url}&hora=lt${timeEnd}`;
    }

    const myHeaders = new Headers();

    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token || subjectToken);
    myHeaders.append('Content-Type', 'application/fhir+json');
    if (subjectId) myHeaders.append('subject-id', subjectId);
    if (typeof subjectRole === 'string' && subjectRole !== '') myHeaders.append('role', subjectRole);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        console.log('response', response)
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getExamFullTimeline'));

    verifyUnauthorized(MHD_response);

    if (typeof MHD_response === 'string') return [MHD_response];

    let nextUrlTimeline = MHD_response.link.filter(
      (li) => li.relation === 'next',
    );

    try {
      nextUrlTimeline = nextUrlTimeline[0].url;
      nextUrlTimeline = `${MHD}/${nextUrlTimeline.slice(nextUrlTimeline.indexOf('?_getpages'))}`;
    } catch (err) {
      nextUrlTimeline = null;
    }

    const retTimeline = this.getEntriesTimeline(MHD_response);
    logDebug('nextUrlTimeline', nextUrlTimeline);
    if (nextUrlTimeline) return { retTimeline, nextUrlTimeline };
    return { retTimeline };
  }

  static async getReport({
    binaryId, organizationId, subjectToken, subjectId, subjectRole, purposeOfUse,
  }) {
    try {
      await xdsToken.getBearerToken();
      const token = await retrieveData('token');
      const applicationToken = await retrieveData('application_token');

      // const query = `?&subject:Patient.identifier=urn:oid:${patient_oid}%7C${patientId}&status=${status}
      //    &format=1.3.6.1.4.1.54413.1.1.6.2%7Ctdd`;
      const url = `${MHD}/Binary/${binaryId}`;

      const myHeaders = new Headers();

      myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
      myHeaders.append('Accept', 'application/fhir+json');
      if (organizationId) myHeaders.append('organization-id', organizationId);
      myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
      myHeaders.append('X-User-Token', token || subjectToken);
      if (subjectId) myHeaders.append('subject-id', subjectId);
      if (typeof subjectRole === 'string' && subjectRole !== '') myHeaders.append('role', subjectRole);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const MHD_response = await fetchWithTimeout(url, requestOptions)
        .then((response) => {
          if (response.status !== 200) throw new Error(response.status);
          return response.json();
        })
        .catch((err) => MHDRequests.catchError(err, 'getReport'));

      verifyUnauthorized(MHD_response);

      if (typeof MHD_response === 'string') return MHD_response;

      if (MHD_response?.contentType === 'text/xml') {
        let responseXml = MHD_response.data;
        try {
          responseXml = String(responseXml);
          responseXml = atou(responseXml);
          const ref = extractDocType(responseXml);
          const info_json = extract(responseXml, ref);
          return info_json;
        } catch (err) {
          //
        }
      } else if (MHD_response?.contentType === 'application/pdf') {
        try {
          const responsePdf = {
            pdf: String(MHD_response.data),
          };
          return responsePdf;
        } catch (err) {
          //
        }
      }
    } catch (error) {
      //
      return 'internal_error';
    }
    return null;
  }

  static async getProfessionalPlaces({
    patientId: username,
    subjectToken,
    organizationId,
    purposeOfUse,
    subjectId,
    subjectRole,
  }) {
    try {
      // eslint-disable-next-line no-param-reassign
      username = username.replace(/\D/g, '');
    } catch (err) {
      // eslint-disable-next-line no-param-reassign
      username = null;
    }
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (DEBUGMODE) logDebug('MHDRequests: GetPatientJSON: token :', token);
    const patient_oid = verifyCPF(username) ? oids.cpf : oids.cns;

    // const url = `${MPI_FHIR}/Patient/${patient_oid}-${username}`;
    const url = `${MPI_FHIR}/Patient/2.16.840.1.113883.13.237-00305501062`;
    // const url = "https://jsonplaceholder.typicode.com/users";

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('X-User-Token', token || subjectToken);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    if (purposeOfUse) myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    if (subjectId) myHeaders.append('subject-id', subjectId);
    if (typeof subjectRole === 'string') myHeaders.append('role', subjectRole);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    // eslint-disable-next-line prefer-const
    let MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getProfessionalPlaces'));

    verifyUnauthorized(MHD_response);

    if (DEBUGMODE) logDebug('demograph MHD_response :>> ', JSON.stringify(MHD_response));
    if (typeof MHD_response === 'string') return MHD_response;
    switch (MHD_response.gender) {
      case 'male' || 'M' || 'm':
        MHD_response.gender = 'Masculino';
        break;
      case 'female' || 'F' || 'f':
        MHD_response.gender = 'Feminino';
        break;
      default:
        MHD_response.gender = 'Outro';
    }
    if (DEBUGMODE) logDebug('MHD_response: getProfessionalPlaces:', MHD_response);
    return MHD_response;
  }

  static async getPatientJson({
    patientId: username,
    subjectToken,
    organizationId,
    purposeOfUse,
    subjectId,
    subjectRole,
  }) {
    try {
      // eslint-disable-next-line no-param-reassign
      username = username.replace(/\D/g, '');
    } catch (err) {
      // eslint-disable-next-line no-param-reassign
      username = null;
    }
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (DEBUGMODE) logDebug('MHDRequests: GetPatientJSON: token :', token);
    const patient_oid = verifyCPF(username) ? oids.cpf : oids.cns;

    const url = `${MPI_FHIR}/Patient/${patient_oid}-${username}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('X-User-Token', token || subjectToken);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    if (purposeOfUse) myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    if (subjectId) myHeaders.append('subject-id', subjectId);
    if (typeof subjectRole === 'string') myHeaders.append('role', subjectRole);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    // eslint-disable-next-line prefer-const
    let MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getPatientJson'));

    verifyUnauthorized(MHD_response);

    if (DEBUGMODE) logDebug('demograph MHD_response :>> ', JSON.stringify(MHD_response));
    if (typeof MHD_response === 'string') return MHD_response;
    switch (MHD_response.gender) {
      case 'male' || 'M' || 'm':
        MHD_response.gender = 'Masculino';
        break;
      case 'female' || 'F' || 'f':
        MHD_response.gender = 'Feminino';
        break;
      default:
        MHD_response.gender = 'Outro';
    }
    if (DEBUGMODE) logDebug('MHD_response: getPatientJson:', MHD_response);
    return MHD_response;
  }

  static async getPatientSummary({
    patientId, 
    organizationId, 
    purposeOfUse,
    subjectId,
    subjectToken,
    subjectRole,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    // const organizationId = '9911936';
    if (DEBUGMODE) logDebug('MHDRequests: GetPatientJSON: token :', token);
    const url = `${MHD}`;

    const isCpf = verifyCPF(patientId);
    const patient_oid = isCpf ? oids.cpf : oids.cns;
    
    const myHeaders = new Headers();

    myHeaders.append('Authorization', `Bearer ${applicationToken || subjectToken}`);
    myHeaders.append('Accept', 'application/fhir+json');
    if (organizationId) myHeaders.append('organization-id', organizationId);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token || subjectToken);
    if (subjectId) myHeaders.append('subject-id', subjectId);
    // if (subjectId) myHeaders.append('subject-id', subjectId);
    if (typeof subjectRole === 'string') myHeaders.append('role', subjectRole);

    const numberOfDocsToRequest = 5;
    const bundleEntries = [
      {
        request: {
          method: 'GET',
          url: `Observation?code=http://loinc.org|55284-4&subject=Patient/${patient_oid}-${patientId}&_sort=-date&_count=1`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `Observation?code=http://loinc.org|29463-7&subject=Patient/${patient_oid}-${patientId}&_sort=-date&_count=1`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `Observation?code=http://loinc.org|8302-2&subject=Patient/${patient_oid}-${patientId}&_sort=-date&_count=1`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `Condition?code=2.16.840.1.113883.6.3|,http://www.saude.gov.br/fhir/r4/CodeSystem/BRCID10|&subject=Patient/${patient_oid}-${patientId}&_sort=-recorded-date&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `Condition?code=2.16.840.1.113883.6.139|,http://www.saude.gov.br/fhir/r4/CodeSystem/BRCIAP2|&subject=Patient/${patient_oid}-${patientId}&_sort=-recorded-date&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `AllergyIntolerance?patient=Patient/${patient_oid}-${patientId}&_sort=-date&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `Procedure?patient=Patient/${patient_oid}-${patientId}&_sort=-date&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `MedicationRequest?subject=Patient/${patient_oid}-${patientId}&_sort=-authoredon&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `MedicationDispense?subject=Patient/${patient_oid}-${patientId}&_sort=-whenhandedover&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `DocumentReference?subject=Patient/${patient_oid}-${patientId}&type:coding=Sum%C3%A1rioAlta.v4.0,Sum%C3%A1rio%20de%20Alta%20v1.0&_sort=-period&status=current&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `DocumentReference?subject=Patient/${patient_oid}-${patientId}&type:coding=ResultadoExames_FESF_V0.1&_sort=-period&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `ServiceRequest?subject=Patient/${patient_oid}-${patientId}&_sort=-authored&_count=${numberOfDocsToRequest}`,
        },
      },
      {
        request: {
          method: 'GET',
          url: `DocumentReference?subject=Patient/${patient_oid}-${patientId}&type:coding=RegistroImunobiologico_FESF_v1.0&_sort=-period&_count=${numberOfDocsToRequest}&status=current`,
        },
      },
      {
        request: {
          method: "GET",
          url: `Immunization?patient=Patient/${patient_oid}-${patientId}&_sort=-date&_count=${numberOfDocsToRequest}`
        }
      }
    ];

    const body = {
      meta: {
        profile: [
          'http://ehrrunner.com/fhir/StructureDefinition/PatientSummary',
        ],
      },
      entry: bundleEntries,
      resourceType: 'Bundle',
    };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };

    // eslint-disable-next-line prefer-const
    let MHD_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getPatientSummary'));

    verifyUnauthorized(MHD_response);

    if (DEBUGMODE) {
      logDebug(
        'demograph JSON MHD_response :>> ',
        JSON.stringify(MHD_response),
      );
    }
    return MHD_response;
  }

  static async getListOrganization(city = null, name = null) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    if (DEBUGMODE) logDebug('MHDRequests: GetOrganizationJSON: token :', token);
    let url = `${MHD}/Organization`;
    if (city || name) url = `${url}?`;
    if (city) url = `${url}address-city=${city}`;
    if (name && city) url = `${url}&name=${name}`;
    if (name && !city) url = `${url}name=${name}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getListOrganization'));

    verifyUnauthorized(organizationJSON);

    if (DEBUGMODE) {
      logDebug(
        'MHDRequests: GetOrganizationJSON: response : ',
        JSON.stringify(organizationJSON),
      );
    }
    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getOrganization(organization) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    if (DEBUGMODE) logDebug('MHDRequests: GetOrganizationJSON: token :', token);
    const url = `${MHD}/Organization?identifier=${organization}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationsSearchResult = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getOrganization'));

    const organizationJSON = organizationsSearchResult.entry[0]?.resource;

    verifyUnauthorized(organizationJSON);

    if (DEBUGMODE) {
      logDebug(
        'MHDRequests: GetOrganizationJSON: response : ',
        JSON.stringify(organizationJSON),
      );
    }
    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getOrganizationName(organization) {
    try {
      const organizationData = await MHDRequests.getOrganization(organization);

      if (typeof organizationData === typeof '') return organization;
      return organizationData.alias[0];
    } catch (err) {
      return organization;
    }
  }

  static async getDocumentsVaccinates({
    patientId,
    timeBeginVerify,
    timeLateVerify,
  }) {
    const patientOid = CNSisValid(patientId) ? oids.cns : oids.cpf;
    const url = `${MHD}/DocumentReference?subject=Patient/${patientOid}-${patientId}&type:coding=RegistroImunobiologico_FESF_v1.0&_sort=-date&date=ge${timeBeginVerify}&date=le${timeLateVerify}`;
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    const purposeOfUse = await retrieveData('purposeOfUse');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    // myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const requestResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getDocumentsVaccinates'));
    verifyUnauthorized(requestResponse);

    return requestResponse;
  }

  static async getDocumentBinaryVaccine({ idBinary }) {
    const url = `${MHD}/Binary/${idBinary}`;
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    const purposeOfUse = await retrieveData('purposeOfUse');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    // myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    let requestResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => MHDRequests.catchError(err, 'getDocumentsVaccinates'));

    verifyUnauthorized(requestResponse);

    if (requestResponse?.contentType === 'text/xml') {
      let responseXml = requestResponse.data;
      try {
        responseXml = String(responseXml);
        responseXml = atou(responseXml);
        const ref = extractDocType(responseXml);
        const info_json = extract(responseXml, ref);
        return info_json;
      } catch (err) {
        logDebug('erro na tentiva de conversão do Binary: ', err);
      }
    } else requestResponse = 'error to take xml';
    return requestResponse;
  }
}
export default MHDRequests;
