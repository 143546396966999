import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import 'semantic-ui-css/semantic.min.css';
import { MenuItem } from 'components';

const PrincipalHeaderBottom = ({ redirectToPage, optionsMenu }) => {
  const [activeItem, setActiveItem] = useState('Meus Registros');

  function handleItemClick(e, { name }) {
    setActiveItem(name);
    redirectToPage(name);
  }

  return (
    <div className="menu-small-screen">
      <Menu
        widths={optionsMenu?.length || 0}
        fluid
        // xs
        secondary
        icon
      >
        {optionsMenu.map((option, i) => (
          <MenuItem
            key={`menu-item-${option.id}-${i}`}
            activeItem={activeItem === option.id}
            tooltip={option.name}
            name={option.id}
            iconName={option.iconName}
            handleItemClick={handleItemClick}
          />
        ))}
      </Menu>
    </div>
  );
};

PrincipalHeaderBottom.propTypes = {
  redirectToPage: PropTypes.func.isRequired,
  optionsMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      onAccess: PropTypes.func.isRequired,
      active: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { PrincipalHeaderBottom };
