import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { css } from 'aphrodite';
import striptags from 'striptags';

import { ArchetypeMenu } from '../ArchetypeMenu';

import styles from '../Style';

export default function SurveillanceData(props) {
  const { data } = props;

  const MENU_OPTIONS = [
    'Caracterização da Notificação',
    'Informações Clínicas',
    'Endereço Residencial',
    'Conclusão',
    'Local Provável de Infecção',
  ];

  const [item, setItem] = useState(MENU_OPTIONS[0]);

  const infoDiv = (label, value) => (value
      && (
      <div style={{ width: '100%', marginRight: '5%', marginTop: 0 }}>
        <Row>
          <Col>
            <div id="Visualizer_detail_container">
              <p className={css(styles.level4label)}>{`${striptags(label)}`}</p>
            </div>
          </Col>
          <Col xs={8}>
            <p className={css(styles.level4value)}>
              {striptags(`${value}`)}
            </p>
          </Col>
        </Row>
        <hr />
      </div>
      )
  );

  const caracterizacaoNotificacaoDiv = (caracterizacao_notificacao) => (
    <div>
      {infoDiv('Identificador', caracterizacao_notificacao.id)}
      {infoDiv('Tipo', caracterizacao_notificacao.tipo_notificacao)}
      {infoDiv('Data', caracterizacao_notificacao.data_notificacao)}
      {infoDiv('Agravo / Doença', caracterizacao_notificacao.agravo_doenca)}
      {infoDiv('Data dos Primeiros Sintomas', caracterizacao_notificacao.data_primeiros_sintomas)}
      {infoDiv('Unidade de Saúde', caracterizacao_notificacao.unidade_de_saude)}
    </div>
  );

  const infoClinicasDiv = (info_clinicas) => (
    <div>
      {infoDiv('Gestante', info_clinicas.gestante)}
    </div>
  );

  const enderecoResidenciaDiv = (endereco_residencia) => (
    <div>
      {infoDiv('Páis', endereco_residencia.gestante)}
      {infoDiv('CEP', endereco_residencia.cep)}
      {infoDiv('Estado', endereco_residencia.uf)}
      {infoDiv('Cidade', endereco_residencia.cidade)}
      {infoDiv('Distrito', endereco_residencia.distrito)}
      {infoDiv('Zona', endereco_residencia.zona)}
      {infoDiv('Latitude', endereco_residencia.lat)}
      {infoDiv('Longitude', endereco_residencia.lng)}
    </div>
  );

  const conclusaoDiv = (conclusao) => (
    <div>
      {infoDiv('Data da Investigação', conclusao.data_investigacao)}
      {infoDiv('Classificação Final', conclusao.classificacao_final)}
      {infoDiv('Critério de Confirmação / Descarte', conclusao.criterio_confirmacao_descarte)}
      {infoDiv('Evolução do Caso', conclusao.evolucao_caso)}
      {infoDiv('Doença Relacionada ao Trabalho', conclusao.doenca_relacionada)}
      {infoDiv('Data de Óbito', conclusao.data_obito)}
      {infoDiv('Data de Encerramento', conclusao.data_encerramento)}
      {infoDiv('Autóctone', conclusao.autoctone)}
    </div>
  );

  const fonteInfeccaoDiv = (fonte_infeccao) => (
    <div>
      {infoDiv('País', fonte_infeccao.pais)}
      {infoDiv('CEP', fonte_infeccao.cep)}
      {infoDiv('Estado', fonte_infeccao.uf)}
      {infoDiv('Cidade', fonte_infeccao.cidade)}
      {infoDiv('Distrito', fonte_infeccao.distrito)}
    </div>
  );

  const getCurrentInfoContainer = () => {
    if (data === null) return <></>;
    switch (item) {
      case MENU_OPTIONS[1]:
        return infoClinicasDiv(data.info_clinicas);
      case MENU_OPTIONS[2]:
        return enderecoResidenciaDiv(data.endereco_residencia);
      case MENU_OPTIONS[3]:
        return conclusaoDiv(data.conclusao);
      case MENU_OPTIONS[4]:
        return fonteInfeccaoDiv(data.fonte_infeccao);
      default:
        return caracterizacaoNotificacaoDiv(data.caracterizacao_notificacao);
    }
  };

  return (
    <div>
      <div>
        <div className={css(styles.archetypeMenuContainer)}>
          <Row>
            <Col xs={4} sm={4} md={4} lg={3} xl={3}>
              {ArchetypeMenu([MENU_OPTIONS], setItem, item)}
            </Col>
            <Col xs={8} sm={8} md={8} lg={9} xl={9} style={{ paddingLeft: '60px' }}>
              {getCurrentInfoContainer()}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
