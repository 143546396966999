import { SupervisorAccount } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import React from 'react';

import PropTypes from 'prop-types';

import './CustomTab.css';

export function CustomTab({ type, children, title }) {

    let iconType;

    if (type == 'person') {
        iconType = <SupervisorAccount style={{ fontSize: 40, color: '#08824A' }} />;
    } else if (type == 'share') {
        iconType = <ShareIcon style={{ fontSize: 35, color: '#08824A' }} />;
    }

    return (
        <>
        <div className="customtab-container">
            <span className="customtab-underline">

                { iconType }

                <span className="customtab-title">
                    { title }
                </span>

            </span>
        </div>
        
        <div className="customtab-section">
            { children }
        </div>
        </>
);
    
}

CustomTab.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired
}

