import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LoadMoreScroll, NoReports, Loading,
} from 'components';
import MHDRequests from 'services/MHDRequests';
import { verifyCPF } from 'utils/AuxiliarFunctions';
import { oids } from 'utils/Constants';
import { formatTimeline } from 'utils/fhirParser';
import { useTranslation } from 'react-i18next';
import { TimelineFilterSelector } from './TimelineFilterSelector';
import Timeline from './Timeline/Timeline';
import './TimelineDrawer.css';

export function TimelineViwer(props) {
  const {
    patientId, 
    organizationId, 
    selectReport, 
    subjectId, 
    purposeOfUse,
    subjectToken,
    subjectRole,
  } = props;

  const [loading, setLoading] = useState(true);
  const [timelineLength, setTimelineLength] = useState(5);
  const { t } = useTranslation('PatientSummary');

  const [allDocuments, setAllDocuments] = useState([]);
  const [documentsShown, setDocumentsShown] = useState([]);
  const [timelineFilter, setTimelineFilter] = useState({
    display: t('TimelineDrawer-patientSummary-timeline-chooseFilter-button'),
    types: [],
  });

  const getTimelineType = async ({ types }) => {
    if (!Array.isArray(types)) return;
    const currentTab = allDocuments[types.toString()];
    const { documents } = currentTab;

    setTimelineLength(documents.length);

    const data = await formatTimeline(documents, subjectToken);
    if (data && Array.isArray(data)
    && data.length) setDocumentsShown(data[0]);
  };

  const loadDocuments = async () => {
    setLoading(true);
    const { types } = timelineFilter;

    let preloadedDocumentsInfo = allDocuments[types.toString()];
    if (!preloadedDocumentsInfo) preloadedDocumentsInfo = {};

    const patientOid = verifyCPF(patientId) ? oids.cpf : oids.cns;

    const { retTimeline, nextUrlTimeline: nextUrl } = await MHDRequests.getFullTimeline({
      patientId,
      organizationId,
      oid: patientOid,
      next: preloadedDocumentsInfo?.nextUrlLink,
      type: types.toString(),
      purposeOfUse,
      subjectId,
      subjectToken,
      subjectRole,
    });

    if (typeof retTimeline === 'string') {
      setLoading(false);
      return null;
    }

    let allTabDocuments = preloadedDocumentsInfo?.documents ? [...preloadedDocumentsInfo.documents] : [];
    if (Array.isArray(retTimeline)) {
      allTabDocuments = [...allTabDocuments, ...retTimeline.map((item) => item.resource)];
    }

    const newAllDocuments = { ...allDocuments };
    newAllDocuments[types.toString()] = {
      documents: allTabDocuments,
      nextUrlLink: nextUrl,
    };
    setAllDocuments(newAllDocuments);
    return null;
  };

  useEffect(() => {
    if (allDocuments[timelineFilter.types.toString()]) {
      getTimelineType({ types: timelineFilter.types }).then(
        () => setLoading(false),
      );
    } else loadDocuments();
  }, [allDocuments, timelineFilter]);

  return (
    <div className="timelineDrawer-container">
      <div className="timelineDrawer-upperArea">
        <div className="timelineDrawer-filter">
          <TimelineFilterSelector
            onChooseType={({ display, types }) => setTimelineFilter({ types, display })}
            display={timelineFilter.display}
          />
        </div>
        {loading && <Loading type="linear" />}
      </div>
      <React.Fragment className="timelineDrawer-loadMoreScroll">
        <LoadMoreScroll
          length={timelineLength}
          hasNext={!!allDocuments[timelineFilter.types.toString()]?.nextUrlLink}
          action={async () => loadDocuments()}
        >
          {!documentsShown.length && !loading ? 
            <div style={{ maxWidth: '40vw', marginLeft: '25%' }}>
              <NoReports />
            </div>
          : 
            <Timeline
              data={documentsShown}
              selectReport={selectReport}
            />
          }
        </LoadMoreScroll>
      </React.Fragment>
    </div>
  );
}

TimelineViwer.propTypes = {
  organizationId: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
  selectReport: PropTypes.func.isRequired,
  subjectId: PropTypes.string.isRequired,
  purposeOfUse: PropTypes.string,
  subjectToken: PropTypes.string,
  subjectRole: PropTypes.string,
};

TimelineViwer.defaultProps = {
  purposeOfUse: 'Atendimento',
  subjectToken: '',
  subjectRole: '',
};
