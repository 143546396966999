import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18n';
import moment from 'moment';
import {
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow
} from '@material-ui/core';
import { Jumbotron } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';

import { retrieveData } from 'utils/AuxiliarFunctions';
import consentRequests from 'services/consentRequests';

import '../ConsentsPage.css';

import { Loading } from 'components';
import { Tab } from 'components/Tab';
import { TabPanel } from 'components/TabPanel';
import { SearchSharp } from '@material-ui/icons';

const AccessHistoryPage = () => {

    const history = useHistory();

    const [is_loading, setIsLoading] = useState(false);

    const [all_history, setAllHistory] = useState([]);
    const [filtered_history, setFilteredHistory] = useState([]); 
    const [my_history, setMyHistory] = useState([]);
    const [search, setSearch] = useState('');
    
    const [active_tab, setActiveTab] = useState(0);

    const t = (translations, customGroup) => {
        return i18n.t(translations, { ns: customGroup ? customGroup : 'ConsentsPage' });
    }
  
    const getHistory = async (subject = null) => {
      let consent_history;
  
      const cpf = await retrieveData('username');
      if (subject === cpf) {
        [consent_history] = await consentRequests.getSelfHistory(subject);
      } else if (subject) {
        [consent_history] = await consentRequests.getHistory(subject);
      } else {
        [consent_history] = await consentRequests.getHistory();
      }
      if (consent_history) {
        if (typeof consent_history === 'string') {
          return [];
        }
        
        return consent_history;
      }

      return [];
    }
    
    const getData = async () => {
        setIsLoading(true);
        const patient_id = await retrieveData('username');
        const resps = await Promise.all([
            getHistory(),
            getHistory(patient_id)
        ]);
        const avaiable_history = resps[0].filter(item => item.sujeito.nome !== undefined);
        setAllHistory(avaiable_history);
        setFilteredHistory(avaiable_history);
        setMyHistory(resps[1]);
        setIsLoading(false);
    }
    
    useEffect(() => {
        (async () => await getData())();
    }, []);
    
    const onSearch = (event) => {
        event.persist();
        setSearch(event.target.value);

        setFilteredHistory(() => {
            console.log('event.target.value', all_history.filter(item => {
                console.log('teste', item.sujeito.nome.toLowerCase().includes(event.target?.value.toLowerCase()) || item.sujeito.id.includes(event.target?.value))
                return item.sujeito.nome.toLowerCase().includes(event.target?.value.toLowerCase()) || item.sujeito.id.includes(event.target?.value);
            }))
            if (event.target?.value === '') return all_history;
            return all_history.filter(item => {
                console.log(item.sujeito.nome.toLowerCase(), event.target?.value.toLowerCase())
                console.log('teste', item.sujeito.nome.toLowerCase().includes(event.target?.value.toLowerCase()))
                return item.sujeito.nome.toLowerCase().includes(event.target?.value.toLowerCase()) || 
                item.sujeito.id.includes(event.target?.value);
            });
        });
    }

    const emptyList = () => (
        <div className="d-flexs justify-content-center w-100">
            <Jumbotron
                className="accent-color-secondary"
                style={{ marginTop: '4%', backgroundColor: 'transparent' }}
            >
                <p style={{ textAlign: 'center' }}>
                    <Icon name="file text" size="large" />
                </p>
                <p style={{ textAlign: 'center' }}>
                    {t('ConsentCard-listShare-dontShare')}
                </p>
            </Jumbotron>
        </div>
    )
        
    if (is_loading) return (
        <div className="consentsPage-loading">
            <Loading size="large" />
        </div>
    );

    return (
        <div className='consentsPage-container'>
            <div className="d-flex align-items-center mb-4">
                <button className='mr-1 mt-1 back-btn' onClick={() => history.goBack()}>
                    <Icon name='arrow left' />
                </button>
                <h1 className='m-0'>{t('ConsentsPage-buttomConsents-howSeenMyRegister')}</h1>
            </div>
            <Tab 
                selected={active_tab} 
                className="consents-tab"
            >
                <TabPanel title={t('ConsentsPage-history-acessHistory')}>
                    <div className='pt-4'>
                        <div className="ui large search">
                            <div className="ui icon input">
                                <input 
                                    type='text'
                                    // id='searchBox'
                                    placeholder={'Filtrar por nome ou CPF...'}
                                    value={search}
                                    onChange={onSearch}
                                />
                                <SearchSharp className='search-icon' />
                            </div>
                        </div>
                        {filtered_history.length === 0 
                            ? emptyList() 
                            : (
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={170}><h5>CPF</h5></TableCell>
                                                <TableCell><h5>Nome</h5></TableCell>
                                                <TableCell><h5>Data</h5></TableCell>
                                                <TableCell><h5>Hora</h5></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filtered_history.map((item, index) => (
                                                <TableRow key={`history-item-${index}`}>
                                                    <TableCell>{item.sujeito?.id}</TableCell>
                                                    <TableCell>{item.sujeito?.nome}</TableCell>
                                                    <TableCell>{moment(item.hora).format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell>{moment(item.hora).format('HH:mm')}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                            )
                        }
                    </div>
                </TabPanel>
                <TabPanel title={t('MyHistory-listHistory-myPast')}>
                    {my_history.length === 0 
                        ? emptyList() 
                        : (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><h5>CPF</h5></TableCell>
                                        <TableCell><h5>Nome</h5></TableCell>
                                        <TableCell><h5>Data</h5></TableCell>
                                        <TableCell><h5>Hora</h5></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {my_history.map((item, index) => (
                                        <TableRow key={`my-history-item-${index}`}>
                                            <TableCell>{item.sujeito?.id}</TableCell>
                                            <TableCell>{item.sujeito?.nome}</TableCell>
                                            <TableCell>{moment(item.hora).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell>{moment(item.hora).format('HH:mm')}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )
                    }
                </TabPanel>
            </Tab>
        </div>
    )
}

export default AccessHistoryPage