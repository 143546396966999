import React, { useEffect, useState } from 'react';
import { features } from 'configs';

import {
  storeData,
  removeData,
  logoutFromPortal,
} from 'utils/AuxiliarFunctions';

import { NewSearchPatient } from 'pages/NewSearchPatient';
import { NewConsentsPage } from 'pages/NewConsentsPage';
import Visualizer from 'pages/Visualizer/Visualizer';
import ProfilePage from 'pages/ProfilePage/ProfilePage';
import ConsentsPage from 'pages/ConsentsPage';
import PolicyPage from 'pages/PolicyPage/PolicyPage';
// import { SOAP } from 'pages/SOAP';
import { useVisualizerContext } from 'contexts/VisualizerContext';
import AccessHistoryPage from 'pages/ConsentsPage/AccessHistoryPage';

export function Pages(roles) {
  return [
    {
      id: 'busca',
      active: features.searchPatients && roles.isProfessional,
      name: 'pages-titlePages-titleSearchPatient',
      iconName: 'search',
      newLayout: true,
      title: 'pages-titlePages-titleSearchPatient',
      path: '/busca',
      onAccess: async () => {},
      component: () => <NewSearchPatient />,
    },
/*     {
      id: 'buscaAntiga',
      active: features.searchPatients && roles.isProfessional,
      name: 'pages-titlePages-titleSearchPatient',
      iconName: 'search',
      title: 'pages-titlePages-titleSearchPatient',
      path: '/buscaAntiga',
      onAccess: async () => {},
      component: () => <SearchPatient />,
    }, */
    // {
    //   id: 'soap',
    //   active: false,
    //   name: 'S.O.A.P',
    //   iconName: 'hospital',
    //   title: 'S.O.A.P',
    //   path: '/soap',
    //   onAccess: async () => {
    //   },
    //   component: () => (<SOAP />),
    // },
    {
      id: 'registros',
      active:
        features.reports || features.searchPatients || roles?.isProfessional,
      title: 'pages-titlePages-titleRegisters',
      iconName: ' ',
      name: 'pages-titlePages-titleRegisters',
      path: '/registros',
      onAccess: async () => {},
      component: () => <Visualizer />,
    },
    {
      id: 'meusregistros',
      active: features.reports,
      name: 'pages-titlePages-titleMyRegister',
      iconName: 'paste',
      title: 'pages-titlePages-titleMyRegister',
      path: '/meusregistros',
      onAccess: async () => {},
      component: function VisualizerWithContext() {
        const { resetVisualizerState } = useVisualizerContext();
        const [loading, setLoading] = useState(true);
        useEffect(() => {
          resetVisualizerState();
          setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        if (loading) return <></>;
        return <Visualizer />;
      },

    },
    {
      id: 'perfil',
      active: features.profile,
      name: 'pages-titlePages-nameProfile',
      iconName: roles?.isProfessional ? 'user md' : 'user',
      title: 'pages-titlePages-titleProfile',
      path: '/perfil',
      onAccess: async () => {
        await storeData('purposeOfUse', '');
      },
      component: () => <ProfilePage />,
    },
    {
      id: 'compartilhamentos',
      active: features.consent && !roles.isProfessional,
      name: 'pages-titlePages-nameShare',
      iconName: 'share alternate',
      title: 'pages-titlePages-titleShare',
      path: '/compartilhamentos',
      onAccess: async () => {
        await removeData('purposeOfUse');
        await removeData('cnesToUse');
      },
      component: () => <ConsentsPage />,
    },
    {
      id: 'historico-acesso',
      active: true,
      name: 'pages-titlePages-myAccessess',
      // iconName: 'share alternate',
      title: 'pages-titlePages-myAccessess',
      path: '/historico-acesso',
      onAccess: async () => {},
      component: () => <AccessHistoryPage />,
    },
   {
      id: 'compartilhamentosProfissional',
      active: features.consent && roles.isProfessional,
      name: 'pages-titlePages-nameShare',
      iconName: 'share alternate',
      newLayout: true,
      title: 'pages-titlePages-titleShare',
      path: '/compartilhamentosProfissional',
      onAccess: async () => {
        await removeData('purposeOfUse');
        await removeData('cnesToUse');
      },
      component: () => <NewConsentsPage />,
    },
    {
      id: 'privacidade',
      active: features.policy,
      name: 'pages-titlePages-namePrivacy',
      iconName: 'user secret',
      title: 'pages-titlePages-titlePrivacy',
      path: '/privacidade',
      onAccess: async () => {
        await storeData('purposeOfUse', '');
      },
      component: () => <PolicyPage />,
    },
    {
      id: 'sair',
      active: true,
      name: 'pages-titlePages-nameLogout',
      iconName: 'power off',
      path: '/sair',
      onAccess: async () => {
        await logoutFromPortal();
        // await BackToLogin();
        window.location.assign(`${process.env.REACT_APP_ENDPOINT_XDSB_domain}/portal/#/login`);
      },
      component: null,
    },
  ];
}

export function getInitialPageId(roles) {
  var initialPageId = 'busca';

  if (!roles.isProfessional) {
    initialPageId = 'meusregistros';
  }
  
  return initialPageId;
} 