/* eslint-disable no-console */
/* eslint-disable camelcase */
import base64 from 'base-64';
import { endpoints } from 'configs';
import {
  retrieveData,
  storeData,
  removeData,
  fetchWithTimeout,
  BackToLogin,
  logDebug,
} from 'utils/AuxiliarFunctions';

const {
  token_api, portalConsumerKey, portalConsumerSecret, principalDomain,
} = endpoints;
const DEBUGMODE = false;

const FIVE_MINS = 600;
const TEN_MINS = 1200;

export default class xdsToken {
  static async getBearerToken() {
    let token_expiration = await retrieveData('Token_exp');
    const token = await retrieveData('application_token');
    token_expiration = Date.parse(token_expiration);
    if (token_expiration && token_expiration !== 'Invalid Date') token_expiration = new Date(token_expiration);
    else token_expiration = new Date(2000, 1, 1, 0, 0, 0, 0);
    let gov = await retrieveData('govBR');
    gov = gov === 'true';
    const password = await retrieveData('password');
    const limitTime = Date.parse(new Date());
    const difference = (Date.parse(token_expiration) - limitTime) / 1000;
    const condRefresh = (gov !== 'null' && gov !== null) ? (difference < FIVE_MINS) && (difference > 0) : (difference < TEN_MINS) && (difference > 0);
    const condFirstTime = (!token_expiration || token_expiration < new Date()) && password !== 'null' && !!password;
    const firstTimeGov = await retrieveData('firstTimeGov');
    if (gov) {
      const applicationToken = await xdsToken.getApplicationToken(true);
      await storeData('application_token', applicationToken.access_token);
      if (condRefresh && !firstTimeGov) {
        const random = Math.floor(Math.random() * 1000000);
        const url = `https://sso.acesso.gov.br/authorize?client_id=ehrrunner.com&redirect_uri=https%3A%2F%2F${principalDomain}%2Fcallback&response_type=code&scope=openid%20email%20profile%20phone&state=${random}&code_challenge_method=S256`;
        await storeData('firstTimeGov', true);
        window.location.replace(url);
      }
      return retrieveData('token');
    }

    if (condRefresh) {
      const token_response = await xdsToken.getTokenRefresh(true);
      token_expiration = new Date();
      token_expiration.setSeconds(token_expiration.getSeconds() + token_response.expires_in);
      await storeData('Token_exp', token_expiration);
      //await storeData('token', token_response.access_token);
      if (token_response.refresh_token) await storeData('refresh_token', token_response.refresh_token);
      else await storeData('refresh_token', 'false');
      return token_response.access_token;
    }
    if (condFirstTime && !gov) {
      const token_response = await xdsToken.getAPIMToken(true);
      if (!(token_response === 'code:logAgain')) {
        token_expiration = new Date();
        token_expiration.setSeconds(token_expiration.getSeconds() + token_response.expires_in);
        await storeData('Token_exp', token_expiration);
        //await storeData('token', token_response.access_token);
        await storeData('application_token', token_response.access_token);
        await storeData('refresh_token', token_response.refresh_token);
        return token_response.access_token;
      }
      return token;
      // BackToLogin();
    }
    // const username = await retrieveData('username');
    // const condLoginPage = !username || (token_expiration < new Date());
    // if (condLoginPage) {
    //   await storeData('userLogged', false);
    //   logDebug('5. returning nulll')
    //   return null;
    //   // document.location.reload(); // TODO: corrigir reload no painel gestao
    // }
    return token;
  }

  static async getAPIMToken() {
    const url = `${token_api}`;
    const basic_token = base64.encode(`${portalConsumerKey}:${portalConsumerSecret}`);
    const username = await retrieveData('username');
    const password = await retrieveData('password');
    await removeData('password');
    if (DEBUGMODE) logDebug(`xdsToken: getToken: basic_token ${basic_token}`);

    const request = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        Authorization: `Basic ${basic_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=password&username=${username}&password=${password}`,
    };

    const xds_domain_response = await fetchWithTimeout(url, request)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response);
      }).catch((err) => {
        switch (err.message) {
          case ('500'):
            return 'internal_error';
          case ('400'):
            return 'bad_request';
          default:
            return ('network_error');
        }
      });

    if (typeof xds_domain_response === 'string') return 'code:logAgain';

    const responseJson = await xds_domain_response.json();
    if (DEBUGMODE) logDebug(`xdsToken: getToken: response ${JSON.stringify(responseJson)}`);
    return responseJson;
  }

  static async getApplicationToken(object = false) {
    let gov = await retrieveData('govBR');
    gov = gov === 'true';
    let token_expiration = await retrieveData('Token_exp');
    token_expiration = Date.parse(token_expiration);
    if (token_expiration && token_expiration !== 'Invalid Date') token_expiration = new Date(token_expiration);
    else token_expiration = new Date(2000, 1, 1, 0, 0, 0, 0);
    const limitTime = Date.parse(new Date());
    const difference = (Date.parse(token_expiration) - limitTime) / 1000;
    const condRefresh = (difference < FIVE_MINS);
    // se for de govBR, retornar application token ou pegar novo application token caso tenha expirado
    if (gov) {
      if (condRefresh) return xdsToken.getTokenRefresh(object);
      const token = await retrieveData('application_token');
      if (object) return { expires_in: token_expiration.getSeconds(), access_token: token };
      return token;
    }
    // se não for de govBR, retornar token ou pegar novo token do APIM caso tenha expirado
    if (condRefresh) return xdsToken.getTokenRefresh(object);
    const token = await retrieveData('application_token');
    if (object) return { expires_in: token_expiration.getSeconds(), access_token: token };
    return token;
  }

  static async getTokenRefresh(object = false) {
    const refresh_token = await retrieveData('refresh_token');
    if (refresh_token === 'false') BackToLogin();
    // const applicationToken = await xdsToken.getApplicationToken();
    // logDebug('application token', applicationToken);
    // await storeData('application_token', applicationToken.access_token);
    // await removeData('refresh_token');
    const url = `${token_api}`;
    const basic_token = base64.encode(`${process.env.REACT_APP_PORTAL_PACIENTE_CONSUMER_KEY}:${process.env.REACT_APP_PORTAL_PACIENTE_CONSUMER_SECRET}`);
    if (DEBUGMODE) logDebug(`xdsToken: getToken: basic_token${basic_token}`);

    const request = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        Authorization: `Basic ${basic_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refresh_token}`,
    };

    const xds_domain_response = await fetchWithTimeout(url, request)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response);
      }).catch((err) => {
        switch (err.message) {
          case ('500'):
            return 'internal_error';
          case ('400'):
            return 'bad_request';
          default:
            return ('network_error');
        }
      });

    if (typeof xds_domain_response === 'string') return {};

    const responseJson = await xds_domain_response.json();
    if (DEBUGMODE) logDebug(`xdsToken: getToken: response ${JSON.stringify(responseJson)}`);
    await storeData('refresh_token', responseJson.refresh_token);
    const token_expiration = new Date();
    token_expiration.setSeconds(token_expiration.getSeconds() + responseJson.expires_in);
    //await storeData('token', responseJson.access_token);
    await storeData('application_token', responseJson.access_token);
    await storeData('Token_exp', token_expiration);
    if (object) return responseJson;
    return responseJson.access_token;
  }

  static async getGovBrApplicationToken(object = false) {
    let token_expiration = await retrieveData('Token_exp');
    const token = await retrieveData('token');
    token_expiration = Date.parse(token_expiration);
    if (token_expiration && token_expiration !== 'Invalid Date') token_expiration = new Date(token_expiration);
    else token_expiration = new Date(2000, 1, 1, 0, 0, 0, 0);
    const limitTime = Date.parse(new Date());
    const difference = (Date.parse(token_expiration) - limitTime) / 1000;
    const condRefresh = (difference < FIVE_MINS);
    if (!condRefresh) return token;

    const url = `${token_api}`;
    const basic_token = base64.encode(`${portalConsumerKey}:${portalConsumerSecret}`);
    const request = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        Authorization: `Basic ${basic_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'grant_type=client_credentials',
    };

    const xds_domain_response = await fetchWithTimeout(url, request)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response);
      }).catch((err) => {
        // eslint-disable-next-line no-console

        switch (err.message) {
          case ('500'):
            return 'internal_error';
          case ('400'):
            return 'bad_request';
          default:
            return ('network_error');
        }
      });

    if (typeof xds_domain_response === 'string') return 'code:logAgain';

    const responseJson = await xds_domain_response.json();
    if (DEBUGMODE) logDebug(`xdsToken: getToken: response ${JSON.stringify(responseJson)}`);
    if (object) return responseJson;
    return responseJson.access_token;
  }

  static async grantPasswordLogin(username, password, object = false) {
    const url = `${token_api}`;
    const basic_token = base64.encode(`${portalConsumerKey}:${portalConsumerSecret}`);
    await removeData('password');
    if (DEBUGMODE) logDebug(`xdsToken: getToken: basic_token ${basic_token}`);

    const request = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        Authorization: `Basic ${basic_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=password&username=${username}&password=${password}`,
    };

    const xds_domain_response = await fetchWithTimeout(url, request)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response);
      }).catch((err) => {
        switch (err.message) {
          case ('500'):
            return 'internal_error';
          case ('400'):
            return 'bad_request';
          default:
            return ('network_error');
        }
      });

    if (typeof xds_domain_response === 'string') return 'code:logAgain';

    const responseJson = await xds_domain_response.json();
    if (DEBUGMODE) logDebug(`xdsToken: getToken: response ${JSON.stringify(responseJson)}`);
    if (object) return responseJson;
    return responseJson.access_token;
  }
}
