import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import './SummaryTab.css';
import MHDRequests from 'services/MHDRequests';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import { logDebug } from 'utils/AuxiliarFunctions';
import { useTranslation } from 'react-i18next';

function SummaryTab({
  tab, initialDocuments, selectReport, patientId, organizationId, subjectToken,
}) {
  const [tabOpened, setTabOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [loadedDocuments,
    setLoadedDocuments] = useState(initialDocuments);
  const [loadMoreLinks, setLoadMoreLinks] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation('PatientSummary');

  const TabContent = tab.Component;

  const handleClickTab = () => {
    setTabOpened(!tabOpened);
  };

  function getNextUrl({ resource }) {
    const nextUrl = resource.link.find((link) => link.relation === 'next')?.url;
    return nextUrl;
  }

  const getNumberOfDocuments = () => {
    let numberOfDocs = 0;
    let overload = false;
    initialDocuments.forEach((item) => {
      if (item?.total >= 0) numberOfDocs += item.total;
      else overload = true;
    });
    if (!overload) return numberOfDocs;

    return ' + ';
  };

  const onLoadMoreDocuments = async () => {
    let newDocuments = [];
    let promiseRes = [];
    try {
      setLoading(true);
      promiseRes = await Promise.allSettled(loadMoreLinks.map(
        async (link) => {
          const documentsResponse = await MHDRequests.getMoreSummaryTabDocuments(
            {
              patientId,
              organizationId,
              nextLinkUrl: link,
              subjectToken,
            },
          );
          return documentsResponse;
        },
      ));
      promiseRes = promiseRes.filter((res) => res.status === 'fulfilled');
      newDocuments = promiseRes.map((res) => res.value);
    } catch (err) {
      logDebug(err);
      setLoading(false);
    }
    if (newDocuments.length) setLoadedDocuments(newDocuments);
  };

  const prepareNewDocuments = () => {
    let newResources = [];
    let newLoadMoreLinks = [];
    loadedDocuments.forEach((document) => {
      newLoadMoreLinks = [...newLoadMoreLinks,
        getNextUrl({ resource: document })];
      newResources = [...newResources, ...document.entry];
    });
    newResources = newResources.filter((item) => !!item);
    newResources = newResources.map((item) => item.resource);
    setResources([...resources, ...newResources]);
    setLoadMoreLinks(newLoadMoreLinks.filter((item) => !!item));
    setLoading(false);
    if (tab.initOpen) setTabOpened(true);
  };

  useEffect(() => {
    prepareNewDocuments();
    setTotal(getNumberOfDocuments());
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedDocuments]);

  const loadMoreButton = () => (
    <Button
      variant="outlined"
      disabled={loading}
      onClick={onLoadMoreDocuments}
    >
      {t('myRegisters-patientSummary-healthSummary-tab-button-loadMore')}
    </Button>
  );

  const tabHeader = () => {
    if (!tab.hideName) {
      return (
        <Button
          variant="contained"
          color="custom"
          fullWidth
          disabled={!initialDocuments}
          onClick={handleClickTab}
        >
          <>
            {tabOpened ? (<KeyboardArrowDown />) : (<ChevronRight />) }
            {`${t(`${tab.name}`)} (${total})`}
          </>
        </Button>
      );
    }
    return null;
  };

  const tabContent = () => (
    <>
      <TabContent documents={resources} selectReport={selectReport} />
      {tab.name === "Observações" ? '' : tab.canLoadMore && loadMoreLinks.length ? loadMoreButton() : null }
      {/* {tab.canLoadMore && loadMoreLinks.length
        ? loadMoreButton() : null } */}
    </>
  );

  return (
    <div className="summaryTab-container">
      {tabHeader()}
      <div className="summaryTab-content">
        {(tabOpened || tab.neverCloses) && tabContent()}
      </div>
    </div>
  );
}

SummaryTab.propTypes = {
  organizationId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  tab: PropTypes.shape({
    id: PropTypes.string.isRequired,
    Component: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    hideName: PropTypes.bool.isRequired,
    neverCloses: PropTypes.bool.isRequired,
    initOpen: PropTypes.bool.isRequired,
    canLoadMore: PropTypes.bool.isRequired,
  }).isRequired,
  initialDocuments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { SummaryTab };
