import React from 'react';

import './PopUp.css';

export function PopUp({handleClose, children}) {

    return (
        <div className="popup-box">
            <div className="box">
            <span className="close-icon" id="closePopup" onClick={handleClose}>x</span>
            {children}
            </div>
        </div>
    )
    
}
