import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { Button } from 'components';

function SimpleModal({
  fullWidth, setModal, modal,
  noCancelButton, okButton, onCloseModal, onOkSelected, ...rest
}) {
  let onClose;
  if (onCloseModal) {
    onClose = () => {
      setModal({
        ...modal,
        visible: false,
      });
    } 
  } else onClose = setModal;


  return (
    <Dialog
      open={modal.visible}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={modal.maxWidth || 'xl'}
      fullWidth={fullWidth}
      // fullScreen
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <DialogTitle id="form-dialog-title">
        {modal.title}
      </DialogTitle>
      <DialogContent>
        {modal.body}
      </DialogContent>
      <DialogActions>
        {modal.footer}
        {okButton && (
          <Button
            variant="outlined" color="primary"
            onClick={() => { setModal({
              ...modal,
              visible: false,
            });
            
            if (onOkSelected) {
              onOkSelected();
            }

            }
          }
          >
            {'Ok'}
          </Button>)}
        {!noCancelButton && (
          <Button
            variant="outlined" color="secondary"
            onClick={() => setModal({
              ...modal,
              visible: false,
            })}
          >
            {modal.cancelButton || 'Cancelar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

SimpleModal.defaultProps = {
  modal: {
    title: null,
    body: null,
    footer: null,
    cancelButton: null,
    visible: false,
    maxWidth: null,
  },
  noCancelButton: false,
  onCloseModal: true,
  fullWidth: true,
};

SimpleModal.propTypes = {
  modal: PropTypes.shape({
    title: PropTypes.node,
    body: PropTypes.node,
    footer: PropTypes.node,
    visible: PropTypes.bool,
    cancelButton: PropTypes.string,
    maxWidth: PropTypes.string,
  }),
  setModal: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  noCancelButton: PropTypes.bool,
  onCloseModal: PropTypes.bool,
  onOkSelected: PropTypes.func
};

export { SimpleModal };
