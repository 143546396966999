const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
    paper: {
      padding: '6px 16px',
      width: '100%'
    },
    fwBold: {
        fontWeight: 'bold'
    },
    detailData: {
      textAlign: 'right',
      fontWeight: 'bold'
    },
    bodyWrapper: {
      border: '1px solid',
      borderColor: 'rgb(230, 230, 230)',
      borderRadius: 6,
      padding: '8px 16px'
    }
}))

export default useStyles;